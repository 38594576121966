export function isPwa() {
  // Check for iOS Safari PWA
  if ((window.navigator as any).standalone === true) {
    return true;
  }

  // Check for modern browsers (Chrome, Firefox, etc.)
  if (window.matchMedia('(display-mode: standalone)').matches) {
    return true;
  }

  return false;
}