enum UserEvents {
  land_step_visit = 'land_step_visit',
  push_subscription_show = 'push_subscription_show',
  back_button_click = 'back_button_click',
  pwa_land_install_button_click = 'pwa_land_install_button_click',
  pwa_install_prompt_show = 'pwa_install_prompt_show',
  pwa_install_accept = 'pwa_install_accept',
  pwa_install_cancel = 'pwa_install_cancel',
}

export default UserEvents;
