import { createRouter, createWebHistory } from 'vue-router';

function loadLand(land: string) {
  return () => import(/* webpackChunkName: "land-[request]" */ `@/components/lands/${land}.vue`);
}

export default createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/gplay',
      component: loadLand('GPlay'),
    },
    {
      path: '/direct_short',
      component: loadLand('LandDirectShort'),
    },
    {
      path: '/direct_long',
      component: loadLand('LandDirectLong'),
    },
    {
      path: '/api_short',
      component: loadLand('LandApiShort'),
    },
    {
      path: '/api_long',
      component: loadLand('LandApiLong'),
    },
    {
      path: '/api_short_m_tt',
      component: loadLand('LandApiShortMainstreamTT'),
    },
    {
      path: '/direct_ss_m_tt',
      component: loadLand('LandDirectShortMainstreamTT'),
    },
    {
      path: '/api_short_m_profiles',
      component: loadLand('LandApiShortMainstreamProfiles'),
    },
    {
      path: '/api_short_tt_9',
      component: loadLand('LandApiShortTT9'),
    },
    {
      path: '/api_short_tt_9_gfb',
      component: loadLand('LandApiShortTT9_GFB'),
    },
    {
      path: '/ss',
      component: loadLand('LandSingleStep'),
      props: {
        isBlonde: false,
      },
    },
    {
      path: '/ssb',
      component: loadLand('LandSingleStep'),
      props: {
        isBlonde: true,
      },
    },
    {
      path: '/ssa',
      component: loadLand('LandingSingleStepAfrican'),
    },
    {
      path: '/ssg',
      component: loadLand('LandSingleStepGif'),
    },
    {
      path: '/ssgg',
      component: loadLand('LandSingleStepGradientGif'),
    },
    {
      path: '/a_ssgg',
      component: loadLand('LandSingleStepGradientGif'),
      props: {
        gif: 'fitness_girl',
      },
    },
    {
      path: '/ssgg_b',
      component: loadLand('LandSingleStepGradientGif'),
      props: {
        gif: 'boobs',
      },
    },
    {
      path: '/tg',
      component: loadLand('LandTelegram'),
    },
    {
      path: '/chtg_d',
      component: loadLand('LandChatTg'),
      props: {
        isDirect: true,
      },
    },
    {
      path: '/chtg_a',
      component: loadLand('LandChatTg'),
      props: {
        isDirect: false,
      },
    },
    {
      path: '/chtg_gfb',
      component: loadLand('LandChatTg_GFB'),
      props: {
        isDirect: false,
      },
    },
    {
      path: '/sexting',
      component: loadLand('LandSingleStepSexting'),
    },
    {
      path: '/peach_ss',
      component: loadLand('LandSingleStepPeachGradientGif'),
    },
    {
      path: '/tt2',
      component: loadLand('LandDirectShortTT2'),
    },
    {
      path: '/tt2_2',
      component: loadLand('LandDirectShortTT2'),
      props: {
        video: '_2',
      },
    },
    {
      path: '/tt2_7',
      component: loadLand('LandDirectShortTT2'),
      props: {
        video: '_7',
      },
    },
    {
      path: '/tt2_8',
      component: loadLand('LandDirectShortTT2'),
      props: {
        video: '_8',
      },
    },
    {
      path: '/tt2_9',
      component: loadLand('LandDirectShortTT2'),
      props: {
        video: '_9',
      },
    },
    {
      path: '/61',
      component: loadLand('LandDirectShort61'),
    },
    {
      path: '/65',
      component: loadLand('65'),
    },
    {
      path: '/64_2',
      component: loadLand('64_2'),
    },
  ],
});
